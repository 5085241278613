<template>
	<div :data-path="curPath" class="setting-page">
		<div class="add-btn-box">
			<a-button type="primary" @click="showAdd">
				<a-icon type="plus-circle" />添加
			</a-button>
		</div>
		<div class="setting-wrapper">
			<a-table
				:loading="dataLoading"
				:rowKey="(item, index) => { return index }"
				:columns="columns"
				:data-source="list"
				:bordered="true"
				@change="listChange"
				:pagination="{ hideOnSinglePage: true, current: page.current, total: page.total }"
			>
				<!-- 	<template slot="operation" slot-scope="index, item">
					<a-button size="small" type="danger" @click="toggleShow(item.is_show)">{{item.is_show == 1?'下架':'上架'}}</a-button>
				</template>-->
			</a-table>
		</div>

		<a-modal v-model="show" :title="edit_id ? '修改标签' : '添加标签'" okText="提交" cancelText="取消" @ok="confirmTag" :confirmLoading="editIng" @cancel="cancelTag">
			<a-input type="text" v-model="form.name" placeholder="请输入名字" />
		</a-modal>
	</div>
</template>

<script>
export default {
	data() {
		let columns = [
			{
				title: '序号',
				customRender: (text, record, index) => `${(this.page.current - 1) * 10 + (index + 1)}`
			},
			{
				title: '名称',
				key: 'name',
				dataIndex: 'name'
			}
			// ,
			// {
			// 	title: '操作',
			// 	key: 'operation',
			// 	scopedSlots: { customRender: 'operation' }
			// }
		];
		return {
			editIng: false,
			show: false,
			columns,
			dataLoading: false,
			list: [],
			typeList: [
				{
					add_url: 'ServiceTypeAdd',
					list_url: 'ServiceTypeList',
					delete_url: 'ServiceTypeDelete',
					edit_url: 'ServiceTypeEdit',
					param_name: 'service_type_name',
					param_id: 'service_type_id'
				}, {
					add_url: 'ParkTypeAdd',
					list_url: 'ParkTypeList',
					delete_url: 'ParkTypeDelete',
					edit_url: 'ParkTypeEdit',
					param_name: 'park_type_name',
					param_id: 'park_type_id'
				}, {
					add_url: 'PolicyTypeAdd',
					list_url: 'PolicyTypeList',
					delete_url: 'PolicyTypeDelete',
					edit_url: 'PolicyTypeEdit',
					param_name: 'policy_type_name',
					param_id: 'policy_type_id'
				}, {
					add_url: 'MatchingSetAdd',
					list_url: 'MatchingSetList',
					delete_url: 'MatchingSetDelete',
					edit_url: 'MatchingSetEdit',
					param_name: 'matching_set_name',
					param_id: 'matching_set_id'
				}, {
					add_url: 'PolicyTagAdd',
					list_url: 'PolicyTagList',
					delete_url: 'PolicyTagDelete',
					edit_url: 'PolicyTagEdit',
					param_name: 'policy_tag_name',
					param_id: 'policy_tag_id'
				}
			],
			edit_id: '',
			form: { name: '' },
			curParams: {},
			page: {
				current: 1,
				total: 0
			},
			curType: '',
		}
	},
	computed: {
		curPath() {
			console.log('变化打赏')
			this.loadData()
			return this.$route.fullPath
		}
	},
	created() {

		this.loadData()
	},
	mounted() {
	},
	methods: {
		getList() {
			let t = this
			t.$post(this.curParams.list_url, { limit: 9999 }).then(res => {
				let { code, data, msg } = res
				if (code == 0) {
					t.list = data.list.map(item => {
						let obj = {}
						obj.id = item[t.typeList[t.curType].param_id]
						obj.name = item[t.typeList[t.curType].param_name]

						return obj
					})
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {

			})
		},
		loadData() {
			this.curType = this.$route.query.type
			if (this.curType) {
				this.curParams = this.typeList[this.curType]
				this.columns[0].key = this.curParams.param_name
				this.columns[0].dataIndex = this.curParams.param_name
				this.getList()
			}
		},
		showAdd() {
			this.show = true
		},
		confirmTag() {
			let t = this
			t.editIng = true
			let param = {}
			param[t.curParams.param_name] = t.form.name
			t.$post(t.curParams.add_url, param).then(res => {
				let { code, data, msg } = res
				t.editIng = false
				t.show = false
				if (code == 0) {
					t.$message.success(msg, 1.5)
					t.getList()
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {
				t.editIng = false
				t.show = false
			})
		},
		cancelTag() {
			this.show = false
			this.edit_id = ''
		},
		listChange(e) {
			this.page.current = e.current
			this.getList();
		},
	},

}
</script>

<style lang="less">
.setting-page {
}
</style>
